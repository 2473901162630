<template>
	<b-modal
		title="Change Password"
		size="lg"
		centered
		small
		ok-title="Change Password"
		v-model="isActive"
		@hidden="handleHidden"
		@ok="handleOk"
	>
		<ValidationObserver ref="form">
			<b-row>
				<b-col>
					<ValidationProvider rules="required" v-slot="{ errors }">
						<b-form-group label="New Password">
							<b-form-input
								type="password"
								v-model="newPass"
								:state="errors.length > 0 ? false : null"
							/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
				<b-col>
					<ValidationProvider rules="required" v-slot="{ errors }">
						<b-form-group label="Repeat Password">
							<b-form-input
								type="password"
								v-model="repeatPass"
								:state="errors.length > 0 ? false : null"
							/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
			</b-row>
		</ValidationObserver>
	</b-modal>
</template>

<script>
import expensesService from "../expenses.service"

export default {
	props: {
		show: Boolean,
	},
	data() {
		return {
			isActive: false,
			newPass: null,
			repeatPass: null,
		}
	},
	methods: {
		handleHidden() {
			this.newPass = null,
			this.repeatPass = null,
			this.isActive = false
			this.$emit("closing")
		},
		async handleOk(ev) {
			ev.preventDefault()
			let isValid = this.$refs["form"].validate()
			if (!isValid) {
				this.showErrorSwal("Repeated password doesnt match")
				return
			}
			if (this.newPass != this.repeatPass) { 
				this.showErrorSwal("Repeated password doesnt match")
				return
			}
			let update = await this.showConfirmSwal("Update Password")
			if (!update.isConfirmed) return
			await expensesService.changePassword(this.newPass)
			this.isActive = false
			this.$emit("closing")
		},
	},
	watch: {
		show: function (val) {
			if (val) {
				this.isActive = true
			}
		},
	},
}
</script>
