<template>
	<b-modal
		title="LIST CARDS"
		size="lg"
		centered
		modal
		v-model="isActive"
		title-class="h4 text-white font-weight-bolder"
		header-class="class_modal_js"
		@hidden="handleHidden"
	>
		<div class="d-flex justify-content-end">
			<b-button variant="success" @click="showCreateCard = true"
				><feather-icon
					icon="PlusIcon"
					size="15"
					class="mr-50 text-white"
				/>Create Card</b-button
			>
		</div>
		<b-tabs>
			<b-tab>
				<template #title>
					<feather-icon icon="CheckCircleIcon" />
					<span>Active</span>
				</template>
				<b-card-text>
					<b-table
						ref="activeTable"
						:items="tableProvider"
						:fields="fields"
						:busy="isBusy"
						small
					>
						<template #table-busy>
							<div class="text-center text-danger my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Loading...</strong>
							</div>
						</template>

						<template #cell(actions)="data">
							<b-dropdown size="sm" variant="link" no-caret>
								<template #button-content>
									<feather-icon
										icon="MoreVerticalIcon"
										size="16"
										class="align-middle text-body"
									/>
								</template>
								<b-dropdown-item @click="observeTracking(data.item.id)"
									>Tracking</b-dropdown-item
								>
								<b-dropdown-item @click="observeCard(data.item.id)"
									>Observe</b-dropdown-item
								>
								<b-dropdown-item @click="deleteCard(data.item.id)"
									>Delete</b-dropdown-item
								>
							</b-dropdown>
						</template>
					</b-table>
				</b-card-text>
			</b-tab>
			<b-tab>
				<template #title>
					<feather-icon icon="TrashIcon" />
					<span>Deleted</span>
				</template>
				<b-card-text>
					<b-table
						ref="deletedTable"
						:items="deletedTableProvider"
						:fields="fields"
						small
					>
						<template #cell(actions)="data">
							<b-dropdown size="sm" variant="link" no-caret>
								<template #button-content>
									<feather-icon
										icon="MoreVerticalIcon"
										size="16"
										class="align-middle text-body"
									/>
								</template>
								<b-dropdown-item @click="observeTracking(data.item.id)"
									>Tracking</b-dropdown-item
								>
								<b-dropdown-item @click="observeCard(data.item.id)"
									>Observe</b-dropdown-item
								>
							</b-dropdown>
						</template>
					</b-table>
				</b-card-text>
			</b-tab>
		</b-tabs>
		<hr />
		<template #modal-footer>
			<div class="d-flex justify-content-end">
				<b-button variant="primary" @click="handleHidden"> Close </b-button>
			</div>
		</template>
		<!-- Modals -->
		<create-card-modal
			:show="showCreateCard"
			@closing="showCreateCard = false"
			@creating="$refs['activeTable'].refresh()"
		/>
		<details-modal
			type="card"
			:id="activeCard"
			:show="showDetailsModal"
			@closing="showDetailsModal = false"
		/>
		<tracking-modal
			type="card"
			:id="activeCard"
			:show="showTrackingModal"
			@closing="showTrackingModal = false"
		/>
	</b-modal>
</template>

<script>
import { mapGetters } from "vuex"
import expensesService from "../expenses.service"

import CreateCardModal from "./CreateCardModal.vue"
import DetailsModal from "./DetailsModal.vue"
import TrackingModal from "./TrackingModal.vue"

export default {
	props: {
		show: Boolean,
	},
	components: {
		CreateCardModal,
		DetailsModal,
		TrackingModal,
	},
	data() {
		return {
			isActive: false,
			fields: ["id", "cardholdername", "value", "actions"],
			showCreateCard: false,
			showDetailsModal: false,
			showTrackingModal: false,
			activeCard: -1,
			isBusy:false,
		}
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.$emit("closing")
		},
		async tableProvider() {
			let res = await expensesService.getCards()
			return res
		},
		async deletedTableProvider() {
			let res = await expensesService.getCards(true)
			return res
		},
		observeCard(id) {
			this.activeCard = id
			this.showDetailsModal = true
		},
		observeTracking(id) {
			this.activeCard = id
			this.showTrackingModal = true
		},
		async deleteCard(id) {
			let deleting = await this.showConfirmSwal("Delete Card")
			if (!deleting.isConfirmed) return
			await expensesService.deleteCard(id, this.currentUser.user_id)
			this.$refs["activeTable"].refresh()
			this.$refs["deletedTable"].refresh()
		},
	},
	computed: { ...mapGetters({ currentUser: "auth/currentUser" }) },
	watch: {
		show: function (val) {
			if (val) {
				this.isActive = true
			}
		},
	},
}
</script>
