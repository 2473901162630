<template>
  <b-modal
    title="CREATE CARD"
    size="sm"
    modal
    centered
    v-model="isActive"
    @ok="handleOk"
    title-class="h4 text-white font-weight-bolder"
    header-class="class_modal_js"
    @hidden="handleHidden"
    ok-title="Save"
  >
    <ValidationObserver ref="form">
      <b-row>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Card name">
              <b-form-input
                v-model="card.name"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group label="Card number">
            <b-row>
              <b-col>
                <ValidationProvider
                  rules="required|digits:4"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    maxLength="4"
                    ref="input-bu-1"
                    @input="activeFocus(1, 4)"
                    v-model="card.number1"
                    :state="errors.length > 0 ? false : null"
                  />
                </ValidationProvider>
              </b-col>
              <b-col
                ><ValidationProvider
                  rules="required|digits:4"
                  v-slot="{ errors }"
                  ><b-form-input
                    maxLength="4"
                    ref="input-bu-2"
                    @input="activeFocus(2, 4)"
                    v-model="card.number2"
                    :state="errors.length > 0 ? false : null"
                  /> </ValidationProvider
              ></b-col>
              <b-col
                ><ValidationProvider
                  rules="required|digits:4"
                  v-slot="{ errors }"
                  ><b-form-input
                    maxLength="4"
                    ref="input-bu-3"
                    @input="activeFocus(3, 4)"
                    v-model="card.number3"
                    :state="errors.length > 0 ? false : null"
                  /> </ValidationProvider
              ></b-col>
              <b-col
                ><ValidationProvider
                  rules="required|integer|min:3"
                  v-slot="{ errors }"
                  ><b-form-input
                    maxLength="4"
                    ref="input-bu-4"
                    @input="activeFocus(4, 4)"
                    v-model="card.number4"
                    :state="errors.length > 0 ? false : null"
                  /> </ValidationProvider
              ></b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider rules="required|digits:2" v-slot="{ errors }">
              <b-form-group label="MM">
                <b-form-input
                  maxLength="2"
                  ref="input-bu-5"
                  @click="activeFocus(5, 2)"
                  @change="activeFocus(5, 2)"
                  v-model="card.month"
                  :state="errors.length > 0 ? false : null"
                />
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider rules="required|digits:2" v-slot="{ errors }">
            <b-form-group label="YY">
              <b-form-input
                maxLength="2"
                ref="input-bu-6"
                @input="activeFocus(6, 2)"
                v-model="card.year"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            rules="required|integer|min:3|max:4"
            v-slot="{ errors }"
          >
            <b-form-group label="CVV">
              <b-form-input
                maxLength="4"
                ref="input-bu-7"
                v-model="card.cvv"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import expensesService from "../expenses.service";

import useUpdater from "../useUpdater";
const { shouldUpdate, field } = useUpdater();

export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      isActive: false,
      card: {
        name: null,
        number1: null,
        number2: null,
        number3: null,
        number4: null,
        cvv: null,
        month: null,
        year: null,
      },
    };
  },
  methods: {
    activeFocus: function (index, max) {
        let inputValue = this.$refs?.[`input-bu-${index}`]
        if('input-bu-'+index=='input-bu-5'){
            if(this.card.month > 12){
                this.card.month = '12'; 
            }else if(this.card.month < 10){
                //this.card.month  = '0'+this.card.month;
            }else if(this.card.month <= 0){
                this.card.month  = '0';
            }
        }
        
        if (inputValue.value == null) return;
        if (inputValue.value.length === max - 1) {
            const nextElement = this.$refs?.[`input-bu-${index + 1}`];
            if (nextElement) nextElement.focus();
        }
    },
    async handleOk(ev) {
      ev.preventDefault();
      let isValid = await this.$refs["form"].validate();
      if (!isValid) return;
      this.createCard();
      this.$emit("creating");
      shouldUpdate.value = Symbol();
      field.value = "card";
      this.isActive = false;
      this.$emit("closing");
    },
    handleHidden() {
      this.card = {
        name: null,
        number1: null,
        number2: null,
        number3: null,
        number4: null,
        cvv: null,
        month: null,
        year: null,
      };
      this.isActive = false;
      this.$emit("closing");
    },
    async createCard() {
      let number = `${this.card.number1} ${this.card.number2} ${this.card.number3} ${this.card.number4}`;

      try {
        let res = await expensesService.createCard(
          this.card.month,
          this.card.year,
          number,
          this.card.cvv,
          this.card.name,
          this.currentUser.user_id
        );
        this.$emit("refreshCards");
      } catch (e) {
        console.error(e);
      }
    }
  },
  computed: { 
    ...mapGetters({ currentUser: "auth/currentUser" }),
  },
  watch: {
    show: function (val) {
      if (val) {
        this.isActive = true;
      }
    },
  },
};
</script>
