<template>
	<div>
		<header-slot :clientsSearch="true">
			<template #actions v-if="isValidPassword">

				<b-button
					variant="info"
					@click="showChangePassword = true"
					v-if="isRolePermitted"
				>
					Change password
				</b-button>

				<b-button
					class="ml-2"
					variant="info"
					@click="showListCards = true"
					><tabler-icon icon="CreditCardIcon" size="20"/>
					<span class="ml-1">New/List Cards</span></b-button
				>
				<b-button
					class="ml-2"
					variant="info"
					@click="showListChecks=true"
					><tabler-icon icon="CreditCardIcon" size="20"/>
					<span class="ml-1">New/List E-Checks</span></b-button
				>
			</template>
		</header-slot>

		<div v-if="isValidPassword"> 
			<b-nav class="m-0" card-header pills>
				<b-nav-item
					:to="{ name: 'expenses-index-bussiness' }"
					exact
					exact-active-class="active"
					:link-classes="['px-3', bgTabsNavs]"
					:disabled="!_isLogged"
					>Active</b-nav-item
				>
				<b-nav-item
					:to="{ name: 'expenses-deleted-bussiness' }"
					exact
					exact-active-class="active"
					:link-classes="['px-3', bgTabsNavs]"
					:disabled="!_isLogged"
					>Deleted</b-nav-item
				>
			</b-nav>
			<b-card
				no-body
				class="border-top-primary border-3 border-table-radius px-0"
			>
				<router-view />
			</b-card>
			
			
			<change-password :show="showChangePassword" @closing="showChangePassword = false" />
			<list-cards-modal :show="showListCards" @closing="showListCards = false" />
			<list-checks-modal :show="showListChecks" @closing="showListChecks=false" />
			
		</div>
		<div v-else>
			<module-login-validation />
		</div>
	</div>
</template>

<script>import { mapGetters, mapActions } from "vuex";
import useExpensesAuth from "./useLogin"
import ModuleLoginValidation from "@/views/business/views/settings/components/ModuleLoginValidation.vue"

import ChangePassword from "./components/ChangePassword.vue"
import ListCardsModal from "./components/ListCardsModal.vue"
import ListChecksModal from "./components/ListChecksModal.vue"

const { isLogged } = useExpensesAuth()

export default {
	components: {
		ChangePassword,
		ListCardsModal,
		ListChecksModal,
		ModuleLoginValidation,
	},
	data() {
		return {
			showChangePassword: false,
			showListCards: false,
			showListChecks: false,
		}
	},
	computed: {
		_isLogged: () => isLogged.value,
		...mapGetters({
			currentUser: "auth/currentUser",
			isValidPassword: "BusinessSettings/G_ISVALID_PASSWORD"
		}),
		isRolePermitted() {
			return this.currentUser.role_id == 1 ||this.currentUser.role_id == 2 || this.currentUser.eng_soft;
		}
	},
	created(){
		this.requestPassword();
	},
	beforeDestroy() {
	 	!this.isRolePermitted && this.setValidPassword(false);
	},
	methods: {
		...mapActions({
			setValidPassword: "BusinessSettings/A_UPDATE_VALID_PASSWORD"
		}),
		requestPassword() {
    		if(this.isRolePermitted)
        	this.setValidPassword(true);
    	}
	}
}
</script>
<style>
.bg_primary a{background: #0090e7;
color:#fff !important}

.insert-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.img-fluid {
  max-width: 50%;
  height: auto;
}
.label-class {
  font-size: 1rem;
  font-weight: bold;
}
</style>