import { ref } from "@vue/composition-api"

// This checks for created action in card, check and description modals and triggers
// update in expense creator modal

let shouldUpdate = ref(Symbol())
let field = ref("")

export default function () {
	return { shouldUpdate,field }
}
