<template>
  <b-modal
    title="CREATE E-CHECK"
    size="lg"
    centered
    modal
    title-class="h4 text-white font-weight-bolder"
    header-class="class_modal_js"
    v-model="isActive"
    @ok="handleOk"
    @hidden="handleHidden"
    ok-title="Save"
  >
    <ValidationObserver ref="form">
      <b-row>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Bank Name">
              <b-form-input
                v-model="check.client"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Account Number">
              <b-form-input
                v-model="check.account"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Routing Number">
              <b-form-input
                v-model="check.routing"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Address Name">
              <b-form-input
                v-model="check.address"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import expensesService from "../expenses.service";

import useUpdater from "../useUpdater";
const { shouldUpdate, field } = useUpdater();

export default {
  props: { show: Boolean },
  data() {
    return {
      isActive: false,
      check: {
        account: null,
        client: null,
        routing: null,
        address: null,
      },
    };
  },
  methods: {
    async handleOk(ev) {
      ev.preventDefault();
      let isValid = await this.$refs["form"].validate();
      if (!isValid) return;
      this.createCheck();
      this.$emit("creating");
      shouldUpdate.value = Symbol();
      field.value = "check";
      this.isActive = false;
      this.$emit("closing");
    },
    handleHidden() {
      this.check = {
        account: null,
        client: null,
        routing: null,
        address: null,
      };
      this.isActive = false;
      this.$emit("closing");
    },
    async createCheck() {
      try {
        let res = await expensesService.createECheck(
          this.check.account,
          this.check.client,
          this.check.routing,
          this.check.address,
          this.currentUser.user_id
        );
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: { ...mapGetters({ currentUser: "auth/currentUser" }) },
  watch: {
    show: function (val) {
      if (val) {
        this.isActive = true;
      }
    },
  },
};
</script>
